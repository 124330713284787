import axios from 'axios';
import { afterLogout } from './requestFunction';
import { message } from 'ant-design-vue'
import store from '../store'

export default function () {
  const service = axios.create({
    // timeout: 5000, // 请求超时时间
  });

  // 请求拦截器
  service.interceptors.request.use(
    (config) => {
      store.commit("changeloadingStatus", true)
      // 在发送请求前做些什么
      //   message.success()
      return config;
    },
    (err) => {
      // 在请求错误的时候做些什么
      store.commit("changeloadingStatus", false)
      return Promise.reject(err);
    }
  );

  // 响应拦截器
  service.interceptors.response.use(
    (response) => {
      // 在请求成功对响应数据做处理
      // return response;
      store.commit("changeloadingStatus", false)
      const res = response.data
      if (response.status === 200) {
        if (res.success) {
          // message.success('请求成功！', 3)
          return Promise.resolve(response)
        } else {
          showMessage(res)
          return Promise.resolve({ data: { data: '' } })
        }
      }
    },
    (error) => {
      // 在响应错误的时候做些什么
      // return Promise.reject(err);
      store.commit("changeloadingStatus", false)
      if (error.message.indexOf('401') >= 0) {
        afterLogout('').then((res) => {
          showMessage(res)
        })
      } else {
        showMessage({ showType: 1, errorMessage: error.message })
      }
      // showMessage({ showType: 1, errorMessage: error.message })
      return Promise.reject(error)
    }
  );

  function showMessage(res) {
    //  
    if (res.showType === 1) {
      message.warning({
        content: res.errorMessage,
        duration: 3
      })
    }
    if (res.showType === 2) {
      message.error({
        content: res.errorMessage,
        duration: 0
      })
    }
    if (res.showType === 4) {
      message.info(res.errorMessage, 3)
    }
    // 应用重定向
    if (res.showType === 9) {
      addressJump(res.data.location)
    }
  }


  function addressJump(url) {
    window.open(url, "_self")
  }



  return service;
}
