const rulesModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        num: function (option) {
            if (!option) {
                option = {}
            }
            return [
                {
                    validator: async (rule, value) => {
                        if (value === undefined || value === null) {
                            value = ''
                        }
                        let re = /^[0-9]*$/
                        if (!re.test(value)) {
                            return Promise.reject(option.text || `请输入数字`);
                        }
                        if (option.cb) {
                            let res = option.cb()
                            if (res&&res.noPasse) {
                                return Promise.reject(res.text);
                            }
                        }
                        if (option.required) {
                            if (value === '') {
                                return Promise.reject(`请输入`);
                            }
                        }
                    },
                    required: option.required ? true : false,
                    trigger: "blur",
                },
            ]
        }
    }),
    mutations: {},
    actions: {},
    getters: {}
}
export default rulesModule